@include color-mode(dark) {

  .site-header {border-top: 5px solid #727272; border-bottom: 2px solid #3E4249 !important;}
  .nc-portrait {filter: grayscale(0.25) !important;}
  .nc-invert {filter: invert(0.8) !important;}
  .nc-home-icon a {color: #cfd3d8 !important;}
  .nc-home-icon svg g path {fill: #cfd3d8 !important;}
  .nc-home-icon:hover a {color: $link-hover-color-dark !important;}
  .nc-home-icon:hover svg g path {fill: $link-hover-color-dark !important;}
  .nc-card {background-color: #2a2a2a !important;}
  .nc-card a {color: $link-color-dark !important;}
  .nc-card a:hover {color: $link-hover-color-dark !important;}
  .nc-table th {background-color: $gray-800 !important;}
  .nc-mark {border-bottom: 1px solid $yellow-500 !important;}
  .nc-google-maps {filter: invert(90%) hue-rotate(180deg); border-color: $gray-400 !important;}
  .nc-static-image {filter: invert(1);}
  .link-dark {color: #cfd3d8 !important;}
  .link-dark:hover {color: #e3e6e9 !important;}
  .bg-light {background-color: #1f2123 !important;}
  .bg-white {background-color: black !important;}

}