html {
  position: relative !important;
  min-height: 100% !important;
}

body {
  // @include media-breakpoint-down(sm) {font-size: 102%;}
  margin-bottom: 50px !important; // Used to be 120px when there was a footer.
  touch-action: manipulation;
  min-height: 100% !important;
  overscroll-behavior: contain !important;
}

a {
  text-decoration: none !important;
}

*:focus {
  outline: none;
}

main {
  display: block; // Default value of `display` of `main` element is 'inline' in IE 11.
}

// blockquote {
//   // font-family: $font-family-serif;
//   // font-size: 110%;
//   text-align: justify;
//   // font-style: italic;
//   font-weight: 400;
//   border-radius: 0.75em;
//   background-color: rgba($black, .05);
//   padding: $spacer*1.2;
// }

// blockquote p {
//   margin: 0;
// }

img {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  font-size: 90%;
  white-space: pre-wrap; /* pre */
  overflow: auto;
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  text-align: left;
  >code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

footer {
  font-size: 90%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
}

/* Lists */

ul {
  // list-style-image: url("img/list.gif");
  padding-left: 15px;
  margin-left: 4px;
}

/* Clearfix */

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}
