@charset "utf-8";
html {overflow-y: scroll;}

// Variables
$content-width: 750px !default;
$on-palm: 650px !default;
$on-laptop: 750px !default;

// Mixins
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $font-size-base * $ratio;
}

@import "minima/nc-bootstrap-config";
@import "bootstrap/bootstrap";
@import "minima/theme-toggle";
@import "minima/base";
@import "minima/layout";
@import "minima/nc";
@import "minima/kramdown";
@import "rouge/dracula";
@import "minima/dark-mode";