.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacer} * 2));
  max-width: calc(#{$content-width} - (#{$spacer} * 2));
  margin-right: auto;
  margin-left: auto;
  @extend %clearfix;
  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacer}));
    max-width: calc(#{$content-width} - (#{$spacer}));
    padding-right: calc($spacer / 2);
    padding-left: calc($spacer / 2);
  }
}

.site-header {
  border-top: 5px solid $gray-800;
  border-bottom: 1px solid $gray-400;
  min-height: $spacer * 1.865; // Positioning context for the mobile navigation icon
  position: relative;
}
