/* Footnotes */

sup a.footnote::before {
	content: "[";
}

sup a.footnote::after {
	content: "]";
}

div.footnotes ol li::before,
div.footnotes ol li::marker {
	font-weight: bold !important;
	font-size: 90%;
}

div.footnotes ol li p {
	margin-bottom: calc($spacer / 2) !important;
	-webkit-margin-after: calc($spacer / 2) !important;
	@include relative-font-size(0.9);
}

.reversefootnote {
	display: none;
}