.nc-navbar-brand {@include media-breakpoint-up(md) {width: 100%;}}
.nc-nav-link {@include media-breakpoint-down(md) {font-size: 110%;}}
.nc-btn-details:hover {border-color: $primary !important; color: $primary !important; background: $body-bg !important;}
.nc-portrait {width: 90px; height: 90px;}
.nc-home-icon {width: 70px; text-align: center; margin:0 2px;}
.nc-home-icon svg g path {fill: $dark; opacity: 0.9;}
.nc-home-icon a {width: 70px; text-align: center; margin: 0 auto; color: $dark; opacity: 0.9;}
.nc-home-icon:hover svg g path {fill: $primary; cursor: pointer;}
.nc-home-icon:hover a {color: $primary; cursor: pointer;}
.nc-note {text-align:justify; hyphens:auto; -webkit-hyphens:auto; -ms-hyphens:auto; @include media-breakpoint-down(sm) {font-size: 90%;}}
.nc-note h5, .nc-note h6 {margin: $spacer*2 0 $spacer 0;}
.nc-justify {@include media-breakpoint-up(sm) {text-align: justify;}}
.nc-table {text-align:left; white-space: nowrap; hyphens:none; -webkit-hyphens:none; -ms-hyphens:none;}
.nc-table th {background-color: $gray-200; font-weight: 500 !important; border-bottom: 1px solid $gray-300 !important;}
.nc-serif {font-family: $font-family-serif; font-family: 102%;}
.nc-citation-ninja {background:$green-300 !important;}
.nc-citation-ninja-header {background:$green-700 !important;}
.nc-citation-ninja-header ul li a {color:$green-300 !important;}
.nc-citation-ninja-header ul li a.active {background:$green-300 !important; color:#000000 !important;}
.nc-border-warning {border-color: #dcc88d !important;}
.nc-unsplash a img {transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s;}
.nc-unsplash a img {filter: grayscale(100%); -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%);}
.nc-unsplash a:hover img {filter: grayscale(0%); -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%);}
.nc-bookmarks li:first-of-type {border-top: none !important;}
.nc-mark {border-bottom: 2px solid #ffd700;}
.nc-card {transition-duration: .5s; -webkit-transition-duration: .5s; @include media-breakpoint-down(sm) {font-size: 95%;}}
.nc-card:hover {box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;}

/* bootstrap */
.fw-medium {
  font-weight: 500;
}

.card-columns {
  column-count: 4;
  @include media-breakpoint-only(md) {column-count: 3;}
  @include media-breakpoint-only(sm) {column-count: 2;}
  @include media-breakpoint-only(xs) {column-count: 1;}
}

.accordion-button:not(.collapsed) {
  color: $dark;
  background-color: $light;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/*  vue */
[v-cloak] {display: none;}

/* jekyll-scholar */
ol.bibliography {padding-left: 0;}
ol.bibliography li {list-style-type: none; margin-bottom: $spacer*1.5; font-size: 90%;}

/* animated navbar toogler */

.navbar-toggler {border: 0 !important;}
.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus {outline: none !important; box-shadow: none !important; border: 0 !important;}
.toggler-icon {width: 25px; height: 3px; background-color: $gray-600; display: block; transition: all 0.2s;}
.middle-bar {margin: 5px auto;}

.navbar-toggler.collapsed .toggler-icon {background-color: $gray-600;}
.navbar-toggler.collapsed .top-bar {transform: rotate(0);}
.navbar-toggler.collapsed .middle-bar {opacity: 1; filter: alpha(opacity=100);}
.navbar-toggler.collapsed .bottom-bar {transform: rotate(0);}

/* animation: x */
/*.navbar-toggler .top-bar {transform: rotate(45deg); transform-origin: 10% 10%;}*/
/*.navbar-toggler .middle-bar {opacity: 0; filter: alpha(opacity=0);}*/
/*.navbar-toggler .bottom-bar {transform: rotate(-45deg); transform-origin: 10% 90%;}*/

/* animation: -- */
.navbar-toggler .top-bar {transform: translateY(8px);}
.navbar-toggler .bottom-bar {transform: translateY(-8px);}
